<template>
  <div>
    <div v-if="loading" class="mt-32">
      <Spinner class="bg-white" />
    </div>
    <div v-else-if="task && team && donation">
      <component
        src="https://donorbox.org/widget.js"
        paypalExpress="false"
        :is="'script'"
      ></component>
      <div
        class="flex flex-col md:grid md:gap-4 md:mt-6 md:grid-cols-2"
        :class="{
          'h-full': !isDesktop
        }"
      >
        <BackButton
          v-if="isDesktop"
          id="back-button"
          @click="goBack()"
          :text="team.name"
          type="dark"
          class="col-span-2 w-auto mr-auto"
        />
        <div
          id="position-buttons"
          v-if="!isDesktop"
          class="absolute top-0 w-full pt-16 z-10"
        >
          <div class="relative flex flex-row justify-end px-4 ml-auto">
            <CloseButton
              @click="
                $router.push({
                  name: 'TeamPage'
                })
              "
              type="white"
            />
          </div>
        </div>
        <div class="main-image">
          <VideoRender
            :videoUrl="videoUrl"
            :coverImgUrl="imageUrl"
            class="md:rounded-md"
          >
            <div
              class="h-full bg-cover bg-center md:rounded-md"
              :style="`background-image: url('${imageUrl}');`"
            />
          </VideoRender>
        </div>
        <div
          class="main-info md:rounded-md"
          :class="{
            'bg-white': isDesktop,
            'desktop-single-pane-height': isDesktop
          }"
        >
          <div
            class="flex flex-col p-4 md:p-10 h-full"
            :class="{
              'pb-0': !isDesktop
            }"
          >
            <div>
              <LivesImpactedPill :text="pillText" class="mb-4 mx-auto" />
              <taskDonorboxContent
                :isStepInProgress="false"
                :isStepStart="false"
                :isStepComplete="true"
                :task="kenticoTask"
              ></taskDonorboxContent>
            </div>
            <template>
              <TaskShare
                :shareImageUrl="metaData.imageUrl || imageUrl"
                class="mb-8"
              >
                <SocialShareGeneric
                  v-if="teamUrl && shareData"
                  class="mt-8 z-10"
                  :url="teamUrl"
                  :shareText="
                    kenticoTask.taskShareTitle !== ''
                      ? kenticoTask.taskShareTitle
                      : 'share'
                  "
                  :data="shareData"
                  :hashtags="
                    kenticoTask.taskShareHashtags !== ''
                      ? kenticoTask.taskShareHashtags
                      : 'share'
                  "
                  type="task-completed"
                />
              </TaskShare>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="w-full h-full flex flex-col relative md:pt-6">
        <NotFound :title="$t('message.error')" :description="responseMessage" />
      </div>
    </div>
  </div>
</template>
<script>
import { GET_TEAM_QUERY } from "@/graphql/queries/user/userQueries.js";
import { VERIFY_CONFIRM_DONATION } from "@/graphql/queries/donorbox/donationQueries.js";
import { GET_TASK_KENTICO } from "@/kentico-api/tasks.js";
import { seoMetadata } from "@/utils/seoMetadata";
import { mapGetters } from "vuex";
import { pound } from "@/constants/defaultCurrency.js";
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import NotFound from "@/components/shared/NotFound";
import CloseButton from "@/components/shared/CloseButton.vue";
import TaskShare from "@/components/tasks/TaskShare.vue";
import BackButton from "@/components/shared/BackButton.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import Spinner from "@/components/shared/Spinner.vue";
import taskDonorboxContent from "@/components/tasks/taskDonorbox/ContentBox.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";

export default {
  name: "DonorboxConfirmation",
  components: {
    Spinner,
    SocialShareGeneric,
    taskDonorboxContent,
    VideoRender,
    NotFound,
    CloseButton,
    TaskShare,
    BackButton,
    LivesImpactedPill
  },
  data() {
    return {
      loading: true,
      task: null,
      team: null,
      kenticoTask: null,
      donationId: this.$route.query.id,
      donation: null,
      responseMessage: "",
      metaData: {
        metaDescription: null,
        metaTitle: null,
        metaImageUrl: null,
        metaVideoUrl: null,
        keywords: null
      }
    };
  },
  head: function() {
    const info = seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.metaData.metaDescription,
      metaTitle: this.metaData.metaTitle,
      keywords: this.metaData.keywords,
      metaImageUrl: this.metaData.imageUrl,
      metaVideoUrl: this.metaData.videoUrl
    });
    return {
      ...info,
      title: {
        inner: "Donorbox donation confirmation"
      }
    };
  },
  async mounted() {
    try {
      await this.getTeamAndTasks(this.$route.params.slug);
      if (!this.team) throw this.$t("message.donation.team-not-found");
      this.findTaskOnTeam();
      if (!this.task) throw this.$t("message.donation.task-not-found");
      await this.verifyServerDonation(this.$route.query.id);
      await this.getKontentTask();
    } catch (error) {
      this.team = null;
      this.task = null;
      this.kenticoTask = null;
      this.responseMessage = error;
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    ...mapGetters("auth", ["isLogged"]),
    donorboxCampaignUrl() {
      if (!this.task?.kenticoCodename) return null;

      let url = `${this.task.kenticoCodename}`.replaceAll("/", "-");
      return `https://donorbox.org/embed/${url}`;
    },
    videoUrl() {
      return this.kenticoTask?.completeVideo?.url || "";
    },
    imageUrl() {
      return this.kenticoTask?.completeImage?.url || "";
    },
    teamUrl() {
      if (!this.team?.slug) return false;
      if (!this.task?.kenticoCodename) return false;

      let props = this.$router.resolve({
        name: "TeamPage",
        params: {
          slug: this.team?.slug || ""
        },
        query: {
          sharedFromTask: this.task.kenticoCodename
        }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    shareData() {
      if (!this.task?.id || !this.team?.slug) return false;

      return {
        task: {
          taskId: this.task?.id || "",
          teamSlug: this.team?.slug || ""
        }
      };
    },
    pillText() {
      if (!this.task) return "";

      return `${this.formatPointsOnCompletion(
        this.task.donorboxPointsMultiplier
      )} per ${pound.symbol}`;
    }
  },
  methods: {
    formatPointsOnCompletion,
    async getTeamAndTasks(teamSlug) {
      return await this.$apollo
        .query({
          query: GET_TEAM_QUERY,
          variables: {
            slug: teamSlug
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          this.team = response.data.getTeam;
          return;
        });
    },
    findTaskOnTeam() {
      const task = this.team.visibleTasks.tasks.find(task => {
        return task.kenticoCodename === this.$route.params.taskKontentCodename;
      });
      this.task = task;
    },
    async verifyServerDonation(id) {
      return await this.$apollo
        .mutate({
          mutation: VERIFY_CONFIRM_DONATION,
          variables: {
            teamId: this.team.id,
            taskId: this.task.id,
            donationId: id
          }
        })
        .then(res => {
          const status = res.data.taskActionVerifyDonorbox.status;
          if (status == "paid") {
            this.donation = res.data.taskActionVerifyDonorbox;
          } else {
            throw `donation.${status}`;
          }
        })
        .catch(error => {
          this.responseMessage = this.$t(`message.${error}`);
        });
    },
    async getKontentTask() {
      return await this.$apollo
        .query({
          client: "kenticoClient",
          query: GET_TASK_KENTICO,
          variables: {
            codename: this.task.kenticoCodename
          }
        })
        .then(res => {
          let data = res.data;
          const og = data.task._openGraph;
          this.metaData.metaTitle =
            og.ogTitle !== "" ? og.ogTitle : data.task.title;
          this.metaData.metaDescription =
            og.ogDescription !== ""
              ? og.ogDescription
              : data.task.introContent?.html;
          this.metaData.imageUrl =
            og.ogImage?.url && og.ogImage.url !== ""
              ? og.ogImage.url
              : data.task.introImage?.url;
          this.metaData.videoUrl =
            og.ogVideo !== "" ? og.ogVideo : data.task.introVideo?.url;
          this.kenticoTask = data.task;
          this.$emit("updateHead");
        });
    },
    goBack() {
      this.$router.push({ name: "TeamPage" });
    }
  }
};
</script>
<style lang="postcss" scoped>
.main-image {
  height: 33rem;
}

.desktop-single-pane-height {
  min-height: 44rem;
}

.bg-controls {
  width: 100%;
  height: 180px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  transform: rotate(-180deg);
}

@media screen and (min-width: 768px) {
  .main-image {
    height: 561px;
  }
}

.start-btn {
  min-width: 200px;
}
</style>
