var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"mt-32"},[_c('Spinner',{staticClass:"bg-white"})],1):(_vm.task && _vm.team && _vm.donation)?_c('div',[_c('script',{tag:"component",attrs:{"src":"https://donorbox.org/widget.js","paypalExpress":"false"}}),_c('div',{staticClass:"flex flex-col md:grid md:gap-4 md:mt-6 md:grid-cols-2",class:{
        'h-full': !_vm.isDesktop
      }},[(_vm.isDesktop)?_c('BackButton',{staticClass:"col-span-2 w-auto mr-auto",attrs:{"id":"back-button","text":_vm.team.name,"type":"dark"},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),(!_vm.isDesktop)?_c('div',{staticClass:"absolute top-0 w-full pt-16 z-10",attrs:{"id":"position-buttons"}},[_c('div',{staticClass:"relative flex flex-row justify-end px-4 ml-auto"},[_c('CloseButton',{attrs:{"type":"white"},on:{"click":function($event){return _vm.$router.push({
                name: 'TeamPage'
              })}}})],1)]):_vm._e(),_c('div',{staticClass:"main-image"},[_c('VideoRender',{staticClass:"md:rounded-md",attrs:{"videoUrl":_vm.videoUrl,"coverImgUrl":_vm.imageUrl}},[_c('div',{staticClass:"h-full bg-cover bg-center md:rounded-md",style:(("background-image: url('" + _vm.imageUrl + "');"))})])],1),_c('div',{staticClass:"main-info md:rounded-md",class:{
          'bg-white': _vm.isDesktop,
          'desktop-single-pane-height': _vm.isDesktop
        }},[_c('div',{staticClass:"flex flex-col p-4 md:p-10 h-full",class:{
            'pb-0': !_vm.isDesktop
          }},[_c('div',[_c('LivesImpactedPill',{staticClass:"mb-4 mx-auto",attrs:{"text":_vm.pillText}}),_c('taskDonorboxContent',{attrs:{"isStepInProgress":false,"isStepStart":false,"isStepComplete":true,"task":_vm.kenticoTask}})],1),[_c('TaskShare',{staticClass:"mb-8",attrs:{"shareImageUrl":_vm.metaData.imageUrl || _vm.imageUrl}},[(_vm.teamUrl && _vm.shareData)?_c('SocialShareGeneric',{staticClass:"mt-8 z-10",attrs:{"url":_vm.teamUrl,"shareText":_vm.kenticoTask.taskShareTitle !== ''
                    ? _vm.kenticoTask.taskShareTitle
                    : 'share',"data":_vm.shareData,"hashtags":_vm.kenticoTask.taskShareHashtags !== ''
                    ? _vm.kenticoTask.taskShareHashtags
                    : 'share',"type":"task-completed"}}):_vm._e()],1)]],2)])],1)],1):_c('div',[_c('div',{staticClass:"w-full h-full flex flex-col relative md:pt-6"},[_c('NotFound',{attrs:{"title":_vm.$t('message.error'),"description":_vm.responseMessage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }