import gql from "graphql-tag";

export const VERIFY_CONFIRM_DONATION = gql`
  mutation($teamId: ID!, $taskId: ID!, $donationId: String!) {
    taskActionVerifyDonorbox(
      teamId: $teamId
      taskId: $taskId
      donationId: $donationId
    ) {
      id
      status
    }
  }
`;
